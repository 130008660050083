import React from "react";
import styled from "@emotion/styled";

interface ProgressProps {
  percent?: number;
  loadTime?: number;
}

const ProgressContainer = styled.div`
  --bar-radius: calc(infinity * 1px);
  --background: var(--color-cream-darker);

  position: relative;
  width: 100%;
  background: var(--background);
  border-radius: var(--bar-radius);
`;

const Bar = styled.div`
  --bar-height: 4px;
  --bar-color: var(--gradient-sunrise);
  --bar-radius: calc(infinity * 1px);

  width: calc(var(--bar-percent) * 100%);
  border-radius: var(--bar-radius);
  height: var(--bar-height);
  background: var(--bar-color);
  position: relative;
  transition: width var(--bar-time) linear;
`;

const Progress: React.FC<ProgressProps> = ({
  percent = 0,
  loadTime = 1000
}) => {
  return (
    <ProgressContainer
      style={
        {
          "--bar-percent": percent,
          "--bar-time": `${loadTime}ms`
        } as React.CSSProperties
      }
    >
      <Bar></Bar>
    </ProgressContainer>
  );
};

export default Progress;
