import { Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import React, { FC, useMemo, useState } from "react";
import OnboardingBloc from "src/state/OnboardingBloc/OnboardingBloc";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { TranslationKey } from "src/types/translationKey";
import FaqComponent from "src/ui/components/FaqComponent/FaqComponent";
import Progress from "src/ui/components/Progress/Progress";
import TaskList from "src/ui/components/TaskList/TaskList";
import Translate from "src/ui/components/Translate/Translate";

const Container = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h5`
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
`;

const Counter = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
`;

const ProgressTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

interface Props {
  onClose: () => void;
}

export const OnboardingTasksContext = React.createContext<{
  setTerminalTasks?: (progress: number) => void;
  setTotalTasks?: (count: number) => void;
  terminalTasks: number;
  totalTasks: number;
}>({ terminalTasks: 0, totalTasks: 0 });

export const OnboardingTasksWithoutContext: FC<Props> = ({ onClose }) => {
  const { terminalTasks, totalTasks } = React.useContext(
    OnboardingTasksContext
  );

  const allDone = terminalTasks === totalTasks;

  const faqItems = useMemo(
    () =>
      Array.from({ length: 6 }, (_, index) => ({
        question: `faq.finishAccount.question_${index + 1}` as TranslationKey,
        answer: `faq.finishAccount.answer_${index + 1}` as TranslationKey
      })),
    []
  );

  return (
    <Container>
      <ProgressTitleContainer>
        <Title>
          <Translate msg="onboarding.finishYourAccountSetup" />
        </Title>
        <Counter>
          {terminalTasks}/{totalTasks}
        </Counter>
      </ProgressTitleContainer>
      <Progress percent={terminalTasks / totalTasks} />

      <nine-spacer s="lg" />
      <TaskList
        showBadges
        filterVisibleTasks={(task) =>
          OnboardingBloc.onboardingTasksToShow.includes(task.slug)
        }
        programs={[KnownProgram.ONBOARDING]}
      />

      <nine-spacer s="lg" />
      {allDone ? (
        <Button
          onPress={onClose}
          style={{
            margin: "0 auto"
          }}
        >
          Continue
        </Button>
      ) : (
        <FaqComponent title="faq.finishAccount.title" faqItems={faqItems} />
      )}
    </Container>
  );
};

export const OnboardingTasks: FC<Props> = (props) => {
  const [terminalTasks, setTerminalTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);

  return (
    <OnboardingTasksContext.Provider
      value={{ terminalTasks, setTerminalTasks, totalTasks, setTotalTasks }}
    >
      <OnboardingTasksWithoutContext {...props} />
    </OnboardingTasksContext.Provider>
  );
};
